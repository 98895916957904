// 采购记录详情
<template>
    <div style="padding: 20px;"
         class="baseBg">
        <!-- 返回上一级 -->
        <backUp></backUp>
        <el-row :gutter="20">
            <!-- 左半部 -->
            <el-col :span="12">
                <div class="cardBox">
                    <div class="cardTitle">入库采购</div>
                    <p><span class="label">采购日期：</span><span class="value">{{orderData.purchaseDate || ''}}</span></p>
                    <!-- <p><span class="label">采购部门：</span><span class="value">{{orderData.purchaseDate || ''}}</span></p> -->
                    <p><span class="label">操作人：</span><span class="value">{{orderData.purchasePersonName || ''}}</span></p>
                    <p><span class="label">操作时间：</span><span class="value">{{orderData.createTime || ''}}</span></p>
                    <p><span class="label">备注：</span><span class="value">{{orderData.remark || ''}}</span></p>
                </div>
                <div class="cardBox">
                    <div class="cardTitle">采购信息</div>
                    <div class="listBox"
                         style="padding-top: 0">
                        <div class="tableBox"
                             v-for="(items,index) in orderTable"
                             :key="index">
                            <p class="flex-between-center my-table-head">
                                <span>{{index}}</span>
                                <!-- <span>63000</span> -->
                            </p>
                            <p class="flex-around-center my-table-row"
                               v-for="(item,indexs) in items"
                               :key="indexs">
                                <span style="display: block;width: 200px">{{item.empType || ''}}</span>
                                <span style="display: block;width: 200px">{{item.empTypeModel || ''}}</span>
                                <span style="display: block;width: 200px">{{item.inCount || ''}}</span>
                            </p>
                            <div style="text-align: right;padding: 0 1em 1em;">
                                <span style="margin-right: 40px;">合计：{{getAllCount(items)}}</span>
                                <!-- <span>总价：63000</span> -->
                            </div>
                        </div>
                    </div>

                </div>
            </el-col>
            <!-- 右半部 -->
            <el-col :span="12">
                <div class="listBox">
                    <div class="tableBox">
                        <el-table :data="tableData"
                                  border
                                  v-loading="isloading"
                                  stripe>
                            <el-table-column v-for="col in tableColumns"
                                             :prop="col.prop"
                                             :key="col.label"
                                             :label="col.label"
                                             :min-width="col.width">
                            </el-table-column>
                            <!-- <el-table-column label="操作"
                                             min-width="100">
                                <template slot-scope="scope">
                                    <el-link type="primary"
                                             @click="details(scope.row)">查看</el-link>
                                </template>
                            </el-table-column> -->
                        </el-table>
                        <!-- 分页 -->
                        <Pagination :page="searchForm.page"
                                    :limit="searchForm.size"
                                    :total="pageTotal"
                                    @pagination="paginationChange" />
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import backUp from '@/components/backUp'; // 返回上一级
import httpServer from "@/api/commonAPI.js";
import Pagination from "@/components/Pagination"; // 分页
export default {
    name: "SupplierRecordDetail",
    components: {
        Pagination,
        backUp
    },
    data() {
        return {
            orderData: {}, //订单详情
            orderTable: {}, //采购信息
            searchForm: {
                page: 1,
                size: 20,
            },
            pageTotal: 0,
            tabledata: [],
            isloading: false,
            tableColumns: [
                {
                    prop: "qrCode",
                    label: "设备编号",
                    width: "100",
                },
                {
                    prop: "empType",
                    label: "设备类型",
                    width: "100",
                },
                {
                    prop: "typeModel",
                    label: "规格型号",
                    width: "100",
                },
                // {
                //     prop: "price",
                //     label: "单价",
                //     width: "100",
                // },
            ],
        };
    },
    mounted() {
        this.orderRow = JSON.parse(this.$route.query.data);
        this.getOrderDetail();
        this.getOrderTable();
        this.getDeviceTable();
    },
    methods: {
        /* 获取采购单详情 */
        getOrderDetail() {
            httpServer.findOrderDetail(this.orderRow.id).then((res) => {
                this.orderData = res.content;
            });
        },
        /* 获取采购单列表 list 供应商分组*/
        getOrderTable() {
            httpServer
                .findOrderListByOrderId_(
                    Object.assign(this.searchForm, {
                        orderId: this.orderRow.id,
                        size: 100,
                    })
                )
                .then((res) => {
                    this.orderTable = res.content;
                });
        },
        /* 获取设备列表表格  */
        getDeviceTable(searchForm = this.searchForm) {
            this.isloading = true;
            httpServer
                .findDeviceListByOrderId(
                    Object.assign(searchForm, {
                        orderId: this.orderRow.id,
                    })
                )
                .then((res) => {
                    this.isloading = false;
                    this.tableData = res.content.records;
                    this.pageTotal = res.content.total;
                })
                .catch(() => {
                    this.isloading = false;
                });
        },
        // 分页改变
        paginationChange(e) {
            this.getDeviceTable(Object.assign(this.searchForm, e));
        },
        // 查看详情
        details(item) {
            // 供应商信息管理/采购记录
            this.$router.push({
                path: "/stockManage/deviceInoutRecord",
                query: { data: JSON.stringify(item) },
            });
        },
        //获取供应商下面的供应单总数
        getAllCount(data) {
            return eval(data.map((v) => parseFloat(v.inCount)).join("+"));
        },
    },

    computed: {},
};
</script>
<style scoped>
.my-table-head {
    padding: 15px 20px;
    background: #e8f2fa;
}
.my-table-row {
    padding: 10px;
    border: 1px solid #ddd;
}
</style>